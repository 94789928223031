import { tns } from 'tiny-slider/src/tiny-slider.module';
import { mapStyle } from './styles/mapStyle';

app.home = () => {
  function init() {
    setupSlider();
    homeSlider();
    startGoogleMap();
  }

  let setupSlider = () => {
    const sliderOpts = {
      container: '.home-rotating-banners',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      controls: false,
      responsive: true,
      autoplayButtonOutput: false,
      autoWidth: true,
      autoHeight: true,
    };
    const slider = tns(sliderOpts);
  };

  let homeSlider = () => {
    const homeOpts = {
      container: '.logo-client',
      items: 2,
      slideBy: 1,
      speed: 1000,
      autoplay: true,
      controls: false,
      responsive: {
        400: {
          items: 4,
        },
        992: {
          items: 9,
        },
      },
      nav: false,
      autoplayButtonOutput: false,
    };
    const slider = tns(homeOpts);
  };

  let startGoogleMap = () => {
    let map = new google.maps.Map(document.getElementById('map-banner'), {
      zoom: 3,
      minizoom: 3,
      // The latitude and longitude to center the map (always required)
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
      scrollwheel: false,
      zoomControl: true,
      styles: mapStyle,
    });
    let mapElement = document.getElementById('map-banner');
    let pointMarkers = mapElement.getAttribute('data-location');
    let marked = JSON.parse(pointMarkers);
    let bounds = new google.maps.LatLngBounds();

    marked.forEach(marked => {
      if (marked.lat == null) return;
      if (marked.lng == null) return;

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(marked.lat, marked.lng),
        map: map,
        icon: marked.icon,
        title: marked.name,
      });
      bounds.extend(marker.position);
    });
    map.fitBounds(bounds);
  };

  return init();
};
